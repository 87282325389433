/* This example requires Tailwind CSS v2.0+ */
import { Link } from "react-scroll";

export default function Cabrecera({ img, title, subTitle }) {
  return (
    <>
      {/* <div className="h-0"></div> */}
      <div className="relative bg-indigo-800 h-full" id="inicio">
        <div className="absolute inset-0 ">
          <img className="w-full h-full object-cover" src={img} alt="" />
          <div
            className="absolute inset-0 bg-gray-400"
            style={{ mixBlendMode: "multiply" }}
            aria-hidden="true"
          />
        </div>
        <div className="relative w-full mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8 ">
          <h1 className="text-4xl font-sans tracking-wider text-white text-center mt-20 sm:text-5xl lg:text-8xl ">
            {title}
          </h1>
          <h1 className="text-xl font-sans tracking-wider text-white text-center mt-0 sm:text-5xl lg:text-3xl mb-20 ">
            La salud plena conecta cuerpo, mente y espíritu
          </h1>
          <h1 className="text-xl font-sans tracking-wider text-white text-center mt-32 sm:text-5xl lg:text-2xl  ">
            Conoce mis servicios
          </h1>
          <div className="flex justify-center space-x-24">
            {/* <button className="w-auto h-12 bg-green-950">Contactar</button>
            <button className="w-auto h-12 bg-green-950">Contactar</button> */}
            <Link
              to="servicios"
              smooth={true}
              duration={1000}
              className="w-auto h-10 bg-white rounded-2xl px-16 font-bold hover:bg-gray-300 py-3 "
            >
              Haciendo Click Aquí
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

import Tarjeta from "./components/Tarjeta";
import servicio1 from "../assets/servicio1.jpg";
import servicio2 from "../assets/servicio2.jpg";
import servicio3 from "../assets/servicio3.jpg";

const data = [
  {
    titulo: "Musculoesquelética",
    texto:
      "Rehabilitación enfocada en lesiones del aparato locomotor, consiste en ayudar a las personas a que recuperen el máximo nivel posible de funcionalidad e independencia de su sistema oseo, musculo-tendinoso, ligamentos y articulaciones",
    img: servicio1,
  },
  {
    titulo: "Respiratoria",
    texto:
      "Rehabilitación enfocada para pacientes con problemas respiratorios, trabajando en optimizar la ventilación alveolar, reducir el trabajo diafragmático, mejorar la mecánica ventilatoria y facilitar la eliminación de las secreciones bronquiales",
    img: servicio2,
  },
  {
    titulo: "Neurológica",
    texto:
      "Rehabilitación enfocada en tratar todos aquellos trastornos de las actividades de la vida diaria derivada de alguna patología del sistema nervioso, con el proposito de reintegrar a su vida familiar, laboral y de tiempo libre",
    img: servicio3,
  },
];

export default function KinesiologiaIntegrativa() {
  return (
    <div className="bg-white py-24 sm:py-0" id="servicios">
      <div
        className="mx-auto max-w-7xl px-6 lg:px-8"
        id="Kinesiología Integrativa"
      >
        <div className="mx-auto max-w-2xl lg:text-center">
          {/* <h2 className="text-base font-semibold leading-7 text-indigo-600">Deploy faster</h2> */}
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Kinesiología Integrativa
          </p>
          {/* <p className="mt-6 text-lg leading-8 text-gray-600">
            Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas a elementum
            pulvinar et feugiat blandit at. In mi viverra elit nunc.
          </p> */}
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {data.map((d, index) => {
              return <Tarjeta img={d.img} titulo={d.titulo} texto={d.texto} />;
            })}
          </dl>
        </div>
      </div>
    </div>
  );
}

import Cabecera from "./pages/Cabecera";
import Menu from "./pages/Menu";
import Conoceme from "./pages/Conoceme";
import KinesiologiaIntegrativa from "./pages/KinesiologiaIntegrativa";
import Masajes from "./pages/Masajes";
import MedicinaAlternativa from "./pages/MedicinaAlternativa";

import Slider from "react-slick";

// import imgCabecera1 from "./assets/carrucel1.jpg";
// import bannerImg from "./assets/banner.png";
import bannerImg2 from "./assets/banner2.jpg";

// import imgCabecera2 from "./assets/Inicio.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Certificados from "./pages/Certificados";
import Testimonios from "./pages/Testimonios";
// import Contacto from "./pages/Contacto";
import Contacto2 from "./pages/Contacto2";

function App() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <>
      <div className="overflow-hidden">
        <Menu className="fixed top-0 w-full z-10 bg-white shadow-md" />
        <Slider {...settings} className="w-screen">
          {/* <div className="">
            <Cabecera
              img={imgCabecera1}
              title="Kinesiología Integrativa"
              subTitle="sasdasdasdasdasd"
            />
          </div> */}
          <div className="">
            <Cabecera
              img={bannerImg2}
              title="Kinesiología Integrativa"
              subTitle=""
            />
          </div>
        </Slider>
        <Conoceme />
        <KinesiologiaIntegrativa />
        <Masajes />
        <MedicinaAlternativa />
        <Certificados />
        <Testimonios />
        {/* <Contacto /> */}
        <Contacto2 />
      </div>
    </>
  );
}

export default App;

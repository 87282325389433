import React from "react";

const Tarjeta = ({ img, titulo, texto }) => {
  return (
    <div class="group h-96 w-80 [perspective:1000px]">
      <div class="relative h-full w-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
        <div class="absolute inset-0">
          <img
            class="h-full w-full rounded-xl object-cover shadow-xl shadow-black/40"
            src={img}
            alt=""
          />
        </div>
        <div class="absolute inset-0 h-full w-full rounded-xl bg-black/80 px-12 text-center text-slate-200 [transform:rotateY(180deg)] [backface-visibility:hidden]">
          <div class="flex min-h-full flex-col items-center justify-center">
            <h1 class="text-3xl font-bold">{titulo}</h1>
            {/* <p class="text-lg">Photographer & Art</p> */}
            <p class="text-base">{texto}</p>
            <a
              className="bg-white px-5 py-3 text-black font-semibold mt-3 rounded-xl"
              target="blank"
              href="https://book.timify.com/services?accountId=65ea22b9a92eed7b2d821356&hideCloseButton=true"
            >
              Agenda Aqui
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tarjeta;

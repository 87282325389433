// import { ChevronRightIcon } from "@heroicons/react/20/solid";
// import imgAndres from "../assets/andres.jpg";
import imgAndres2 from "../assets/andres2.png";

// import logo from "../assets/logoFull.png";

export default function Conoceme() {
  return (
    <div className="bg-white">
      {/* <img className="h-32  " src={logo} alt="Your Company" /> */}

      <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20">
        <div className="mx-auto max-w-7xl pb-0  lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 ">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <h1 className="mt-10 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                  Kinesiología Integrativa
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Es un método de valoración que busca un tratamiento integral,
                  enfocado en tratatar a la persona como ser humano completo
                  considerando aspectos biomecánicos, nutricionales,
                  emocionales, mentales y espirituales
                </p>
                <h1 className="mt-10 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                  Sobre Mi
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Soy Kinesiólogo titulado de la Universidad Autónoma de Chile
                  dedicado al área de la medicina integrativa y su abordaje
                  terapéutico. Cuento con formaciones en diversas áreas de la
                  medicina tradicional, como la medicina alternativa y/o
                  complementaria. Mi propuesta de tratamiento consiste
                  identificar y modificar los factores que le están enfermando
                  para lograr el equilibrio, de esta manera orientar y acompañar
                  en todo el proceso de recuperación, ya sean lesiones,
                  condiciones patológicas o dolores de tipos agudos y
                  persistentes (crónicos) con el fin de que los consultantes
                  puedan ser dueñas de su propia salud y función para un
                  correcto reingreso en sus actividades de la vida diaria.
                </p>
                {/* <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="#"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Documentation
                  </a>
                  <a
                    href="#"
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    View on GitHub <span aria-hidden="true">→</span>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
          <img src={imgAndres2} alt="andres cavieres" className="mt-5" />
          {/* <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <div className="shadow-lg md:rounded-3xl">
              <div className="bg-indigo-500 [clip-path:inset(0)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                <div
                  className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36"
                  aria-hidden="true"
                />
                <div className="relative px-6 pt-8 sm:pt-16 md:pl-16 md:pr-0">
                  <div className="mx-auto max-w-2xl md:mx-0 md:max-w-none">
                    <div className="w-screen overflow-hidden rounded-tl-xl bg-gray-900">
                      <div className="flex bg-gray-800/40 ring-1 ring-white/5">
                        <div className="-mb-px flex text-sm font-medium leading-6 text-gray-400">
                          <div className="border-b border-r border-b-white/20 border-r-white/10 bg-white/5 px-4 py-2 text-white">
                            NotificationSetting.jsx
                          </div>
                          <div className="border-r border-gray-600/10 px-4 py-2">App.jsx</div>
                        </div>
                      </div>
                      <div className="px-6 pb-14 pt-6">asdasdasd</div>
                    </div>
                  </div>
                  <div
                    className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 md:rounded-3xl"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  );
}

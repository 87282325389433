import React from "react";
import { useFormik } from "formik";
import axios from "axios";

export default function TestimonioForm() {
  const formik = useFormik({
    initialValues: {
      name: "",
      comment: "",
    },

    onSubmit: async (formValue) => {
      const body = {
        ...formValue,
        owner: "aacc96",
      };
      console.log(body);
      axios
        .post(
          "https://backend-bzt-production.up.railway.app/api/comment/create",
          body
        )
        .then((res) => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  return (
    <div class="md:px-64 px-6 mx-auto">
      <h1 class="text-3xl font-medium title-font text-gray-900 mb-12 text-center">
        Deja tu comentario
      </h1>
      <form onSubmit={formik.handleSubmit}>
        <div class="mb-6">
          <label
            for="name"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
          >
            Nombre
          </label>
          <input
            type="text"
            id="name"
            name="name"
            required
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-azul2 focus:border-azul2 block w-full p-2.5 dark:bg-bg-gray-200 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Nombre"
            onChange={formik.handleChange}
          />
        </div>
        <label
          for="name"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
        >
          Comentario
        </label>
        <div class="mb-4 w-full bg-gray-50 rounded-lg border border-gray-200 dark:bg-gray-200 dark:border-gray-600">
          <div class="py-2 px-4 bg-white rounded-t-lg dark:bg-gray-200">
            <textarea
              id="comment"
              name="comment"
              rows="4"
              class="px-0 w-full text-sm text-gray-900 bg-white border-0 dark:bg-gray-200 focus:ring-0 dark:text-black dark:placeholder-gray-400"
              placeholder="Escribe tu comentario..."
              required
              onChange={formik.handleChange}
            ></textarea>
          </div>
          <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 sm:flex dark:bg-gr dark:border-black dark:text-white">
            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div class="flex items-center pl-3">
                <input
                  id="horizontal-list-radio-license"
                  onChange={formik.handleChange}
                  type="radio"
                  required
                  value="5"
                  name="rate"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-  focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                  for="horizontal-list-radio-license"
                  class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  ⭐⭐⭐⭐⭐
                </label>
              </div>
            </li>
            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div class="flex items-center pl-3">
                <input
                  id="horizontal-list-radio-id"
                  type="radio"
                  onChange={formik.handleChange}
                  value="4"
                  name="rate"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                  for="horizontal-list-radio-id"
                  class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  ⭐⭐⭐⭐
                </label>
              </div>
            </li>
            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div class="flex items-center pl-3">
                <input
                  id="horizontal-list-radio-millitary"
                  type="radio"
                  onChange={formik.handleChange}
                  value="3"
                  name="rate"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                  for="horizontal-list-radio-millitary"
                  class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  ⭐⭐⭐
                </label>
              </div>
            </li>
            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div class="flex items-center pl-3">
                <input
                  id="horizontal-list-radio-millitary"
                  type="radio"
                  onChange={formik.handleChange}
                  value="2"
                  name="rate"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                  for="horizontal-list-radio-millitary"
                  class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  ⭐⭐
                </label>
              </div>
            </li>
            <li class="w-full dark:border-gray-600">
              <div class="flex items-center pl-3">
                <input
                  id="horizontal-list-radio-passport"
                  type="radio"
                  onChange={formik.handleChange}
                  value="1"
                  name="rate"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                  for="horizontal-list-radio-passport"
                  class="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  ⭐
                </label>
              </div>
            </li>
          </ul>
          
        </div>
        <div class="flex justify-center items-center py-2 px-3 border-t ">
            <button
              type="submit"
              class="inline-flex items-center py-4 px-10 text-xl font-medium text-center text-white bg-azul2 rounded-lg focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-900 hover:bg-azul1"
            >
              Publicar
            </button>
          </div>
      </form>
    </div>
  );
}

import Tarjeta from "./components/Tarjeta";
import servicio10 from "../assets/servicio10.jpg";
import servicio41 from "../assets/servicio41.jpg";
// import servicio6 from '../assets/servicio6.jpg'
import servicio9 from "../assets/servicio9.jpg";

const data = [
  {
    titulo: "Descontracturante",
    texto:
      "Técnicas de terapia manual   que van dirigidas a zonas de dolor y que buscan eliminar las contracturas y puntos gatillo que pueden llegar a generarse por estrés, malas posturas, la falta de descanso o una vida demasiado sedentaria o sobre activa.",
    img: servicio10,
  },
  {
    titulo: "Relajación/Piedras Calientes",
    texto:
      "El masaje relajante consiste en la realización de maniobras superficiales en las que la intensidad de la presión es suave y el ritmo lento y reiterativo con el fin de producir un efecto sedante que disminuya los niveles de estrés.",
    img: servicio41,
  },
  {
    titulo: "Drenaje linfático",
    texto:
      "Su objetivo es mejorar la circulación de la linfa y el sistema linfático ideal para situaciones de retención de líquido o edemas.",
    img: servicio9,
  },
];

export default function Masajes() {
  return (
    <div className="bg-white py-24 sm:py-20" id="Masajes">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          {/* <h2 className="text-base font-semibold leading-7 text-indigo-600">Deploy faster</h2> */}
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Masajes
          </p>
          {/* <p className="mt-6 text-lg leading-8 text-gray-600">
            Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas a elementum
            pulvinar et feugiat blandit at. In mi viverra elit nunc.
          </p> */}
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {data.map((d, index) => {
              return <Tarjeta img={d.img} titulo={d.titulo} texto={d.texto} />;
            })}
          </dl>
        </div>
      </div>
    </div>
  );
}

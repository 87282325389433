import Tarjeta from "./components/Tarjeta";
import servicio7 from "../assets/servicio7.jpg";
// import servicio41 from "../assets/servicio41.jpg";
import servicio5 from "../assets/servicio5.jpg";
import servicio6 from "../assets/servicio6.jpg";

const data = [
  {
    titulo: "Moxibustión",
    texto:
      "Tratamiento terapéutico que proviene de la medicina tradicional China basada en la aplicación de calor en diferentes partes de la anatomía humana como método para estimular diferentes puntos de acupuntura con el fin de curar enfermedades o dolencias.",
    img: servicio5,
  },
  {
    titulo: "Digitopuntura y marmaterápia",
    texto:
      "Tiene la finalidad de generar el equilibrio físico bioquímico, orgánico y psíquico mediante la estimulación de marmas (puntos donde se produce una comunicación entre el cuerpo físico, energético, fisiológico y nervioso) mediante la aplicación de técnicas manuales con la aplicación de aceites esenciales",
    img: servicio6,
  },
  {
    titulo: "Biomagnetismo",
    texto:
      "La magnetoterapia es la aplicación del campo magnético de dispositivos electromagnéticos o imanes estáticos permanentes al cuerpo para la obtención de distintos beneficios de salud.",
    img: servicio7,
  },
];
export default function MedicinaAlternativa() {
  return (
    <div className="bg-white py-20 sm:py-0" id="Medicina Alternativa En Manejo Del Dolor">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          {/* <h2 className="text-base font-semibold leading-7 text-indigo-600">Deploy faster</h2> */}
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Medicina Alternativa En Manejo Del Dolor
          </p>
          {/* <p className="mt-6 text-lg leading-8 text-gray-600">
            Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas a elementum
            pulvinar et feugiat blandit at. In mi viverra elit nunc.
          </p> */}
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {data.map((d, index) => {
              return <Tarjeta img={d.img} titulo={d.titulo} texto={d.texto} />;
            })}
          </dl>
        </div>
      </div>
    </div>
  );
}

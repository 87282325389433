
import certificado2 from './../assets/certificado2.jpeg'
import certificado3 from './../assets/certificado3.jpeg'
import certificado4 from './../assets/certificado4.png'
import certificado5 from './../assets/certificado5.png'
import certificado6 from './../assets/certificado6.png'
import certificado7 from './../assets/certificado7.png'
import certificado8 from './../assets/certificado8.png'
import certificado9 from './../assets/certificado9.png'
import certificado10 from './../assets/certificado10.png'
import certificado11 from './../assets/certificado11.png'
import certificado12 from './../assets/certificado12.png'
import certificado13 from './../assets/certificado13.png'
import certificado14 from './../assets/certificado14.png'
import certificado15 from './../assets/certificado15.png'
import certificado16 from './../assets/certificado16.png'


const certificados = [
  {
    name: "Certificado de título Kinesiólogo",
    role: "23 Agosto 2023",
    imageUrl: certificado2,
  },
  {
    name: "Licenciado en Kinesiología",
    role: "23 de Agosto 2023",
    imageUrl: certificado3,
  },
  {
    name: "Diplomado en Medicina Herbolária",
    role: "Marzo 2022",
    imageUrl: certificado9,
  },
  {
    name: "Taller de Terapia y Masajes con Ventosas",
    role: "Octubre 2020",
    imageUrl: certificado4,
  },
  {
    name: "Punción Seca Metamérica",
    role: "Septiembre 2021",
    imageUrl: certificado5,
  },
  {
    name: "Fundamentos de la Marmaterapia y Digitopuntura Ayurveda",
    role: "Mayo 2021",
    imageUrl: certificado6,
  },
  {
    name: "Biomagnetismo Médico con Bioenergética",
    role: "Noviembre 2021",
    imageUrl: certificado7,
  },

  {
    name: "Maseterapia Integral",
    role: "Enero 2020",
    imageUrl: certificado8,
  },
  
  {
    name: "Diplomado en Manejo del Dolor",
    role: "Septiembre 2023",
    imageUrl: certificado10,
  },

  {
    name: "Curso ACLS",
    role: "Abril 2024",
    imageUrl: certificado11,
  },

  {
    name: "Procedimientos De Rehabilitación Pulmonar",
    role: "Febrero 2024",
    imageUrl: certificado12,
  },

  {
    name: "Ventilación Mecánica Invasiva y No Invasiva",
    role: "Abril 2024",
    imageUrl: certificado13,
  },

  {
    name: "Prevención de Infecciones Asociadas a La Antención De Salud (IAAS)",
    role: "Abril 2024",
    imageUrl: certificado14,
  },

  {
    name: "Jornada de autocuidado y bienestar de equipos desde una mirada de salud integrativa",
    role: "Marzo 2024",
    imageUrl: certificado15,
  },
  {
    name: "Curso Biomecánica",
    role: "Febrero 2024",
    imageUrl: certificado16,
  },

  
];

export default function Certificados() {
  return (
    <div className="bg-white" id="trayectoria">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className=" text-center text-2xl font-extrabold tracking-tight sm:text-5xl">
              Certificaciones
            </h2>
          </div>
          <div
            role="list"
            className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
          >
            {certificados.map((certificado) => (
              <div key={certificado.name}>
                <div className="space-y-4">
                  <div className="aspect-w-3 aspect-h-2">
                    <img
                      className="object-cover shadow-lg rounded-lg"
                      src={certificado.imageUrl}
                      alt=""
                    />
                  </div>

                  <div className="space-y-2">
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3>{certificado.name}</h3>
                      <p className="text-azul2">{certificado.role}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

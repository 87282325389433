import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-scroll";
import logo4 from "../assets/logo4.png";

const navigation = [
  { name: "Inicio", to: "inicio" },
  {
    name: "Servicios",
    to: "servicios",
    subMenu: [
      "Kinesiología Integrativa",
      "Masajes",
      "Medicina Alternativa En Manejo Del Dolor",
    ],
  },
  { name: "Reseñas", to: "testimonios" },
  { name: "Contacto", to: "contacto" },
];

export default function Menu() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const handleLinkClick = () => {
    setMobileMenuOpen(false);
  };

  const toggleSubMenu = (menuName) => {
    if (activeSubMenu === menuName) {
      setActiveSubMenu(null);
    } else {
      setActiveSubMenu(menuName);
    }
  };

  return (
    <header className="bg-indigo-600">
      <nav
        className="mx-auto flex w-full items-center justify-between lg:px-8 fixed top-0 z-50 bg-transparent backdrop-filter backdrop-blur-lg"
        aria-label="Global"
      >
        <div className="mr-6">
          <span className="sr-only">Your Company</span>
          <img className="h-28 w-auto" src={logo4} alt="" />
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-indigo-300"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <div key={item.name} className="relative">
              {item.subMenu ? (
                <div
                  onMouseEnter={() => setShowSubMenu(true)}
                  className="relative"
                >
                  <Link
                    to={item.to}
                    smooth={true}
                    duration={1000}
                    className="text-lg font-semibold leading-6 text-black cursor-pointer"
                  >
                    {item.name}
                  </Link>
                  {showSubMenu && (
                    <div
                      onMouseLeave={() => setShowSubMenu(false)}
                      className="absolute z-10 w-48 py-2 mt-2 space-y-2 bg-white rounded-md shadow-lg left-0"
                    >
                      {item.subMenu.map((subItem) => (
                        <Link
                          key={subItem}
                          to={subItem}
                          smooth={true}
                          duration={1000}
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                        >
                          {subItem}
                        </Link>
                      ))}
                      <a
                        href="https://kinnova.cl/"
                        target="_blank"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                        rel="noreferrer"
                      >
                        Servicios Coorporativos
                      </a>
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  to={item.to}
                  smooth={true}
                  duration={1000}
                  className="text-lg font-semibold leading-6 text-black cursor-pointer"
                >
                  {item.name}
                </Link>
              )}
            </div>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a
            target="blank"
            href="https://book.timify.com/services?accountId=65ea22b9a92eed7b2d821356&hideCloseButton=true"
            className="text-lg font-semibold leading-6 text-white bg-azul1 py-3 px-4 rounded-xl hover:bg-azul2"
          >
            Agenda Aquí
          </a>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <button href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </button>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <div key={item.name}>
                    {item.subMenu ? (
                      <div>
                        <button
                          onClick={() => toggleSubMenu(item.name)}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 w-full text-left"
                        >
                          {item.name}
                        </button>
                        {activeSubMenu === item.name && (
                          <div className="ml-4 space-y-2">
                            {item.subMenu.map((subItem) => (
                              <Link
                                key={subItem}
                                to={subItem}
                                onClick={handleLinkClick}
                                smooth={true}
                                duration={1000}
                                className="block px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer"
                              >
                                {subItem}
                              </Link>
                            ))}
                            <a
                              href="https://kinnova.cl/"
                              target="_blank"
                              className="block px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer"
                              rel="noreferrer"
                            >
                              Servicios Coorporativos
                            </a>
                          </div>
                        )}
                      </div>
                    ) : (
                      <Link
                        to={item.to}
                        onClick={handleLinkClick}
                        smooth={true}
                        duration={1000}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
